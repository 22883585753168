export default class FloatingContactCta extends HTMLDivElement {

    constructor() {
        super()
        this.ctaClose = this.querySelector('.cta-close')
        this.backToTop = document.getElementById('backtotop-cta')
    }

    connectedCallback() {

        this.ctaClose.addEventListener('click', (e) => {
            e.preventDefault()
            this.classList.add('hidden')
            this.backToTop.classList.add('backtotop-right');
        })

    }

}
