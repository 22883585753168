window.HegydGtm = {

    pushToDatalayer : function (gtm) {
        if(typeof dataLayer !== 'undefined') {

            Object.keys(gtm).forEach(function(k){
                dataLayer.push(gtm[k]);
            });
        }
    },
};
