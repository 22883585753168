require('../../../../../frontoffice/js/hegyd-gtm.js');

export default class HomeEvent extends HTMLDivElement {

    constructor() {
        super()
    }

    connectedCallback() {
        let dataLayerDatas = JSON.parse($(this).parent().parent().find('.datalayer-event-home').html());
        window.dataLayer = window.dataLayer || [];
        dataLayerDatas.forEach(function (dataLayerData) {
            dataLayer.push({ ecommerce: null });
            dataLayer.push(dataLayerData);
        });
    }

}