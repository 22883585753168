require('../../../../../frontoffice/js/hegyd-gtm.js');

export default class ProductEvent extends HTMLDivElement {

    constructor() {
        super()
    }

    connectedCallback() {
        $(this).find('a.fire-event').on('click', function(e) {
            e.preventDefault();

            let dataLayerDatas = null;

            if (typeof $(this).parent().find('.datalayer-select-data').html() !== 'undefined') {
                dataLayerDatas = JSON.parse($(this).parent().find('.datalayer-select-data').html());
            } else {
                dataLayerDatas = JSON.parse($(this).parent().parent().find('.datalayer-select-data').html());
            }

            window.dataLayer = window.dataLayer || [];

            dataLayer.push({ ecommerce: null });
            dataLayer.push(dataLayerDatas);

            if (typeof $(this).parent().parent().data('url') !== 'undefined') {
                if ($(this).parent().parent().data('url').length > 0) {
                    window.location.href = $(this).parent().parent().data('url');
                }
            } else if ($(this).parent().data('url').length > 0) {
                window.location.href = $(this).parent().data('url');
            }
        });

    }

}
