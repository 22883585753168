"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// jQuery
const jQuery = require("jquery");
window.jQuery = jQuery;
window.$ = jQuery;
// Bootstrap
const bootstrap = require("bootstrap");
window.bootstrap = bootstrap;
// Autres dépendances
require("@ungap/custom-elements");
window.toastr = require('toastr');
require("../../vendor/plomberie-pro-main/src/js/main.js");
