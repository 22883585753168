export default class VideoLightbox extends HTMLDivElement {
    connectedCallback() {
        this.handleVideoLightbox();
    }

    handleVideoLightbox() {
        document.addEventListener('DOMContentLoaded', function () {
            const lightbox = document.getElementById('lightbox');
            const closeBtn = document.querySelector('.lightbox .close');
            const youtubeVideo = document.getElementById('youtube-video');
            const triggers = document.querySelectorAll('.youtube-trigger');
            if (triggers.length > 0) {
                const youtubeLink = triggers[1].getAttribute('data-youtube');

                triggers.forEach(trigger => {
                    trigger.addEventListener('click', function () {
                        lightbox.style.display = 'block';
                        youtubeVideo.src = youtubeLink;
                    });
                });

                closeBtn.addEventListener('click', function () {
                    lightbox.style.display = 'none';
                    youtubeVideo.src = '';
                });

                window.addEventListener('click', function (event) {
                    if (event.target === lightbox) {
                        lightbox.style.display = 'none';
                        youtubeVideo.src = '';
                    }
                });
            }
        });
    }
}
