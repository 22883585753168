import Splide from '@splidejs/splide';
export default class ProductCarousel extends HTMLDivElement {
    connectedCallback() {
        this.mountCarousels();
    }

    mountCarousels() {
        this.mainCarousel = new Splide('.splide.main-carousel', {
            type: 'slide',
            pagination: false,
            arrows: false,
            heightRatio: .8
        });

        this.thumbnailsCarousel = new Splide('.splide.thumbnails-carousel', {
            fixedWidth: 60,
            fixedHeight: 60,
            gap: 8,
            pagination: false,
            arrows: false,
            isNavigation: true
        });

        this.mainCarousel.sync(this.thumbnailsCarousel);
        this.mainCarousel.mount();
        this.thumbnailsCarousel.mount();
    }
}
