export default class ListFilters extends HTMLDivElement {

    constructor() {
        super();
        this.toggleFiltersCtas = document.querySelectorAll('.js-toggle-filters-cta');
    }

    connectedCallback() {

        this.toggleFiltersCtas.forEach(toggleFiltersCta => {
            toggleFiltersCta.addEventListener('click', (e) => {
                e.preventDefault();
                this.classList.toggle('opened');
            })
        })

    }

}