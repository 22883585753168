export default class TableProducts extends HTMLDivElement {

    constructor() {
        super()
        this.toggleMoreItems = this.querySelector('.toggle-more-items')
        this.moreItems = this.querySelector('.more-items')
    }

    connectedCallback() {
        if (this.toggleMoreItems != null) {
            this.toggleMoreItems.addEventListener('click', (e) => {
                e.preventDefault()
                this.toggleMoreItems.style.display = 'none'
                this.moreItems.style.display = 'block'
            })
        }
    }
}