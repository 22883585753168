export default class MainNav extends HTMLElement {

    constructor() {
        super();
        this.togglesNav = document.querySelectorAll('.js-toggle-nav')
        this.headerTop = document.querySelector('.js-header-top')
        this.header = this.parentElement
        this.setIsMobile();
    }

    connectedCallback() {

        // Bind toggles
        this.togglesNav.forEach(toggleNav => {
            toggleNav.addEventListener('click', e => {
                e.preventDefault()
                this.updatePosition()
                this.classList.toggle('opened')
                this.header.classList.toggle('force-visible')
            })
        })

        // Update position
        setTimeout(() => {
            this.updatePosition()
        }, 500);
        window.addEventListener('resize', e => {
            this.updatePosition()
            this.setIsMobile();
        })
        document.addEventListener('scroll', e => {
            this.updatePosition()
        })

        // Toggle child panel
        const navLinksWithChildren = this.querySelectorAll('a.has-children')
        navLinksWithChildren.forEach(navLinkWithChildren => {
            navLinkWithChildren.addEventListener('click', (e) => {
                // Suit le lien s'il est de 2e niveau en mode desktop
                if ( ! this.isMobile && this.isElementChildOfLevel2(e.target)) {
                    return true;
                }

                e.preventDefault()

                // On gère les états actif des liens
                const activeLinks = e.currentTarget.closest('.nav-panel').querySelectorAll('.active')
                activeLinks.forEach(activeLink => {
                    activeLink.classList.remove('active')
                })

                // On ferme tous les panels potentiellement ouverts ...
                const openedPanels = e.currentTarget.closest('.nav-panel').querySelectorAll('.nav-panel.opened')
                openedPanels.forEach(openedPanel => {
                    openedPanel.classList.remove('opened')
                })

                // ... et on ouvre le planel cible
                e.currentTarget.classList.add('active')
                e.currentTarget.nextElementSibling.classList.add('opened')
            })
        })

        // Close panel
        const togglesPanel = this.querySelectorAll('.js-toggle-panel')
        togglesPanel.forEach(togglePanel => {
            togglePanel.addEventListener('click', (e) => {
                e.preventDefault()
                e.currentTarget.closest('.nav-panel').classList.remove('opened')
            })
        })

    }

    updatePosition() {
        if (this.headerTop) {
            const rect = this.headerTop.getBoundingClientRect()
            this.style.setProperty('--pos-top', rect.bottom.toString() + "px")
            this.style.setProperty('--pos-left', rect.left.toString() + "px")
            this.style.setProperty('--pos-width', rect.width.toString() + "px")
        }
    }

    setIsMobile() {
        this.isMobile = window.innerWidth < 992;
    }

    isElementChildOfLevel2(element) {
        let parent = element.parentElement;
        let i = 0;

        while (parent) {
            if (parent.classList.contains('nav-panel-lvl-2')) {
                return true;
            }

            // Prevent inifinite loop
            if (i > 100) {
                break;
            }

            parent = parent.parentElement;
            ++i;
        }

        return false;
    }
}
