export default class CollapseLongContent extends HTMLDivElement {

    constructor() {
        super();
        this.content = this.querySelector('.content')
        this.lineHeight = this.dataset.lineHeight || 20
        this.linesMax = this.dataset.linesMax || 4
        this.maxWidth = this.dataset.maxWidth || null
        this.readMoreLink = null
    }

    connectedCallback() {

        if (this.maxWidth != null && window.innerWidth > parseInt(this.maxWidth)) {
            this.linesMax = 9999
        }

        this.style.setProperty('--lines-max', this.linesMax)

        this.readMoreLink = document.createElement('a')
        this.readMoreLink.classList.add('read-more')
        this.readMoreLink.href = "#"
        this.readMoreLink.innerHTML = "Lire la suite"
        this.appendChild(this.readMoreLink)

        this.readMoreLink.addEventListener('click', e => {
            e.preventDefault()
            this.classList.remove('collapsed')
            this.readMoreLink.style.display = "none"
        })

        this.init()

        window.addEventListener('resize', e => {
            this.init()
        })
    }

    init() {
        if (this.countLines() > this.linesMax) {
            this.classList.add('collapsed')
            this.readMoreLink.style.display = "inline"
        } else {
            this.classList.remove('collapsed')
            this.readMoreLink.style.display = "none"
        }
    }

    countLines() {
        this.classList.remove('collapsed')
        return Math.round(this.content.offsetHeight / this.lineHeight)
    }

}