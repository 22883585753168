export default class QtyInput extends HTMLDivElement {

    constructor() {
        super();
        this.qtyPlus = null;
        this.qtyMinus = null;
        this.input = this.querySelector('input');
        this.changeEvent = new Event('change', {
            bubbles: true,
            cancelable: false,
            composed: true
        });
    }

    connectedCallback() {
        this.qtyPlus = document.createElement('button');
        this.qtyPlus.classList.add('btn-plus');
        this.qtyPlus.innerHTML = '<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.221 5.121H5.485v3.66H4.239V5.12H.502v-1.22h3.737V.242h1.246v3.66H9.22v1.22Z" fill="#fff"/></svg>';
        this.appendChild(this.qtyPlus);

        this.qtyMinus = document.createElement('button');
        this.qtyMinus.classList.add('btn-minus');
        this.qtyMinus.innerHTML = '<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.303 1.876H.584V.656h8.72v1.22Z" fill="#fff"/></svg>';
        this.appendChild(this.qtyMinus);

        this.updateState();

        this.qtyPlus.addEventListener('click', (e) => {
            e.preventDefault();
            this.input.value = parseInt(this.input.value) + 1;
            this.triggerChangeEvent();
        });

        this.qtyMinus.addEventListener('click', (e) => {
            e.preventDefault();
            this.input.value = parseInt(this.input.value) - 1;
            this.triggerChangeEvent();
        });

        this.input.addEventListener('change', (e) => {
            this.updateState();
        });
    }

    updateState() {
        this.qtyMinus.disabled = this.input.disabled || parseInt(this.input.value) === 1;
        this.qtyPlus.disabled = this.input.disabled || parseInt(this.input.value) >= parseInt(this.input.max);
    }

    triggerChangeEvent() {
        if ( ! this.input.disabled) {
            this.input.dispatchEvent(this.changeEvent);
        }
    }
}
