require('bootstrap');
import '@ungap/custom-elements';

import CollapseLongContent from "./components/CollapseLongContent"
customElements.define('collapse-long-content', CollapseLongContent, {extends: 'div'})

import FloatingContactCta from "./components/FloatingContactCta"
customElements.define('floating-contact-cta', FloatingContactCta, {extends: 'div'})

import ListFilters from "./components/ListFilters"
customElements.define('list-filters', ListFilters, {extends: 'div'})

import ListGroupLimit from "./components/ListGroupLimit"
customElements.define('list-group-limit', ListGroupLimit, {extends: 'ul'})

import MainNav from "./components/MainNav"
customElements.define("main-nav", MainNav, {extends: 'nav'})

import ProductCarousel from "./components/ProductCarousel";
customElements.define('product-carousel', ProductCarousel, {extends: 'div'});

import QtyInput from "./components/QtyInput";
customElements.define('qty-input', QtyInput, {extends: 'div'});

import TableProducts from "./components/TableProducts";
customElements.define('table-products', TableProducts, {extends: 'div'});

import ProductEvent from "./components/ProductEvent";
customElements.define('product-event', ProductEvent, {extends: 'div'});

import HomeEvent from './components/HomeEvent';
customElements.define('home-event', HomeEvent, {extends: 'div'});

import VideoLightbox from "./components/VideoLightbox";
customElements.define('video-lightbox', VideoLightbox, {extends: 'div'});

/**
 * Mount auto-sliders
 */
import SplideAutoMount from "./components/SplideAutoMount";
customElements.define('splide-auto-mount', SplideAutoMount, {extends: 'div'});

/**
 * Define css variable for scroll width
 */
window.addEventListener("DOMContentLoaded", (e) => {
   document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px")
})

/**
 * Handle page scroll behaviour
 */

// Hide / reveal header on scroll
const header = document.querySelector('header');

if (header !== null) {
   let currentScrollPosition;
   let previousScrollPosition = window.scrollY || document.documentElement.scrollTop;
   let currentDirection = 0;
   let previousDirection = 0;
   let lastScrollPosition = 0;
   let isHeaderHidden = false;
   const downThreshold = 200;
   const upThreshold = 100;

   const checkScroll = () => {
      currentScrollPosition = window.scrollY || document.documentElement.scrollTop;

      if (currentScrollPosition > previousScrollPosition) {
         currentDirection = 2;
      } else {
         currentDirection = 1;
      }

      if (currentDirection !== previousDirection) {
         toggleHeaderState();
      } else {
         lastScrollPosition = currentScrollPosition;
      }

      previousScrollPosition = currentScrollPosition;
   };

   const toggleHeaderState = () => {
      if (currentDirection === 2 && currentScrollPosition - lastScrollPosition > downThreshold) {
         lastScrollPosition = currentScrollPosition;
         header.classList.add('scrolled');
         isHeaderHidden = true;
      } else if (currentDirection === 1 && lastScrollPosition - currentScrollPosition > upThreshold) {
         lastScrollPosition = currentScrollPosition;
         header.classList.remove('scrolled');
         isHeaderHidden = false;
      }
   };

   window.addEventListener('scroll', checkScroll);

}

/**
 * Back to top
 */


const backtotop = document.querySelector('.js-backtotop-cta');

if (backtotop !== null) {
   const handleScrollPosition = () => {
      let scrollTop = window.scrollY || document.documentElement.scrollTop;
      backtotop.classList.toggle('active', (scrollTop > 150));
   }

   backtotop.addEventListener('click', (e) => window.scrollTo(0, 0));

   window.addEventListener('scroll', (e) => handleScrollPosition());
   window.addEventListener('resize', (e) => handleScrollPosition());

   handleScrollPosition();
}
