export default class ListGroupLimit extends HTMLUListElement {

    constructor() {
        super();
        this.limit = this.dataset.limit || null;
        this.listGroupItems = this.querySelectorAll('.list-group-item');
        [this.toggleMore] = this.parentElement.querySelectorAll('.toggle-more');
    }

    connectedCallback() {
        this.toggleMore.classList.add('d-none');

        if (this.limit !== null && this.limit < this.listGroupItems.length) {

            this.toggleMore.classList.remove('d-none');

            this.listGroupItems.forEach((listGroupItem, index) => {
                if (index + 1 > this.limit) {
                    listGroupItem.classList.add('d-none');
                }
            });

            this.toggleMore.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleMore.classList.add('d-none');
                this.listGroupItems.forEach((listGroupItem) => {
                    listGroupItem.classList.remove('d-none');
                }, false);
            });

        }

    }

}
